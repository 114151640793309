<template>
    <div>
        <the-navigation 
            :navigation-body="$store.state.navigation.body"
            :breakpoint="900"
        />
        
        <Nuxt />

        <client-only>
            <cookie-consent
                :content="$store.state.navigation.text"
                :button-text="$store.state.navigation.button_text"
            />
        </client-only>
        
        <compare-cards />
        <subscription-form v-if="showSubscriptionForm" />
        <Footer :slice="$store.state.footer" />
    </div>
</template>
<script>
import SubscriptionForm from '@/components/includes/TheSubscriptionForm.vue';
import Footer from '@/components/includes/TheFooter.vue';
import CompareCards from '@/components/compare-cards/CompareCardsBar.vue';
import TheNavigation from '@/components/includes/TheNavigation.vue';
import { CookieConsent } from '@swegaming-ab/vue-components';
export default {
    components: {
        CookieConsent,
        TheNavigation,
        Footer,
        CompareCards,
        SubscriptionForm
    },
    async middleware({ store, $prismic, route }) {
        await store.dispatch('fetchStates', { $prismic, route });
    },
    computed: {
        showSubscriptionForm() {
            let path = this.$route.path;
            path += path.endsWith('/') ? '' : '/';
            return ! path.endsWith('/ansok/');
        }
    }
};
</script>
