// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-30510a3e]{font-family:\"Open Sans\"}.font-header[data-v-30510a3e]{font-family:\"Hero\"}.card-sticky-mobile[data-v-30510a3e] .compliance{background-color:#fff}.compliance[data-v-30510a3e]{display:block;text-align:center;padding:8px 0;font-size:.8em;color:#8c98a4;font-weight:lighter}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
