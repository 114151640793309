<template>
    <footer class="footer section">
        <div class="section-inner section-width-lg">
            <div class="info">
                <!-- NOTE: Hard coded but brand? -->
                <h2>Kortio</h2>
                <div v-html="$prismic.asHtml(slice.company_short_text)" />
                <div v-if="socialMediaItems.length" class="social-media-icons">
                    <social-media 
                        v-for="(item, index) in socialMediaItems"
                        :key="index"
                        :url="item.url"
                        :icon="item.icon"
                        :text="item.text"
                    />
                </div>
            </div>
            <div class="columns">
                <ul v-for="group in footerLinks" :key="group.header">
                    <li>{{ group.header }}</li>
                    <li v-for="(iterator, index) in group.links" :key="index">
                        <nuxt-link :to="$prismic.linkResolver(iterator.link)">{{iterator.text}}</nuxt-link>
                    </li>
                </ul>
            </div>            

            <div
                class="copy"
                v-html="$prismic.asHtml(slice.copy_text)"
            />
            <div class="locale-selector">
                <ul>
                    <li v-for="locale in locales" :key="locale.domain">
                        <a :href="locale.domain"><img :src="locale.icon" loading="lazy">{{ locale.country }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import locales from '@/config/locales.config.js';
import SocialMedia from '@/components/SocialMedia.vue';
export default {
    components: {
        SocialMedia
    },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            locales: locales
        };
    },
    computed: {
        socialMediaItems() {
            return this.slice.social_media_items.filter(item => item.url);
        },
        footerLinks() {
            return [1, 2, 3].map((x) => (
                {
                    header: this.slice[`links_column_${x}_header`],
                    links: this.slice[`links_column_${x}`]
                }
            ));
        }
    }
};
</script>

<style lang="scss" scoped>
    footer {
        background: $green;

        .section-inner {
        @include flex(start, start);

            .info::v-deep {
                color: $white;
                flex: 1;
                max-width: 300px;
                margin-right: 80px;

                h2 {
                    margin-bottom: 20px;
                }
            }

            .columns {
                @include device(mobile) {
                    width: 100%;
                    @include grid(1, 40px);
                    margin-top: 40px;
                }

                @include device(pad) {
                    width: auto;
                    flex: 1;
                    @include grid(3, 80px);
                }

                li {
                    @include font-size(14px, 20px);

                    &:first-child {
                        @include font-size(16px, 20px);
                        color: $white;
                        font-weight: bold;
                    }

                    a {
                        text-decoration: none;
                        color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                    }
                }
            }

            .copy::v-deep {
                width: 100%;
                margin-top: 20px;
                padding-top: 20px;
                margin-bottom: 20px;
                padding-bottom: 20px;

            border-top: 1px solid rgba($white, 0.2);
            border-bottom: 1px solid rgba($white, 0.2);
                color: $white;

                p {
                @include font-size(13px, 6px);
                }
            }

            .social-media-icons {
                display: flex;
                flex-flow: wrap row;
                gap: 0.5rem;
                margin-top: 1rem;

                @include device(mobile) {
                    flex-direction: column;
                }

                a {
                    display: flex;
                    gap: 0.25rem; 
                    border-radius: 0.225rem;
                    font-family: $fontText;
                    font-size: 0.75rem;
                    padding: 0.3125rem 0.75rem;
                    color: whitesmoke;
                border: 1px solid rgba(247, 250, 255, 0.1);
                background-color: rgba(247, 250, 255, 0.1);

                    @include device(mobile) {
                        width: 30%;
                    }

                    &:hover {
                        background-color: transparent;
                    border: 1px solid rgba(113, 134, 157);
                    }
                }
            }

            .locale-selector {
                ul {
                    display: flex;

                    li {
                        display: flex;
                        margin-right: 25px;

                        a {
                            display: flex;
                            align-items: center;
                            font-size: 13px;
                            color: $white;

                            img {
                                width: 30px;
                                border-radius: 4px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
